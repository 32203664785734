import { Context } from "global/reduxState/store";
import { setWithoutExpiry } from "global/utils/localStorageUtils";
import { SyntheticEvent, memo, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGetFirstUrlParam from "../../hooks/useGetFirstUrlParam";
import Switch from '@mui/material/Switch';
import styles from "./Search.module.css";
import { FormControlLabel } from "@mui/material";
import { log } from "console";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Search = () => {
	const history = useHistory();

	const [search, setSearch] = useState("");

	const [ stateContext , dispatch] = useContext(Context);
	const [switchValue, setSwitchValue] = useState(false);

	useEffect(() => {
		setSwitchValue(stateContext.typeSearch === "ia" ? true : false)
	}, [])
	
	const handleSubmit = (e: SyntheticEvent) => {
		dispatch({ type: "[filter] reset filters" });
		if(switchValue){
			dispatch( { type: "[typesearch] change typesearch", payload: { typeSearch: 'ia'}} )
		}else{
			dispatch( { type: "[typesearch] change typesearch", payload: { typeSearch: 'keywords'}} )
		}
		setWithoutExpiry('prevQuery', 'undefined');
		setWithoutExpiry('query', search );

		setWithoutExpiry('prevUniversitiesSelected', 'undefined');
		setWithoutExpiry('universitiesSelected', '');
		
		history.push(`results?query=${search}&n_page=${1}`);
		e.preventDefault();
	};

	const handleChange = (e: any) => {
		setSearch(e.target.value);
	};

	const handleSwitch = (e: any) => {
		const newValue = e.target.checked;
		setSwitchValue(newValue)
	};

	const firstParam = useGetFirstUrlParam();

	return (
		<>
		<section className={styles.containerSearch}>
					{/*
						firstParam === "caps" &&
						<FormControlLabel
							value="start"
							control={<Switch color="primary" />}
							label="Búsqueda con IA"
							labelPlacement="start"
							checked={switchValue}
							onChange={handleSwitch}
						/>*/
					}
			
				<form method="get" onSubmit={handleSubmit} className={styles.formSearchBox}>
					<input
						type="text"
						className={styles.search}
						//placeholder="Busca Investigadores, Centros, Tecnologías, Servicios y Equipamiento"
						placeholder="Busca Investigadores, Centros, Tecnologías y Servicios"
						style={{
							fontFamily: "Helvetica Neue, Arial, Helvetica, sans-serif, FontAwesome",
						}}
						onChange={handleChange}
						value={search}
					/>
				</form>
				<p className={styles.subTextSearch}>Buscar por nombre o palabras claves</p>

				{firstParam !== "caps" && (
					<img src="/images/udd/LOGO_CAPS.png" alt="Logo header" className={styles.capsLogo} />
				)}
			</section>
			<hr className={styles.orangeSpacer} style={{ width: "100%", maxWidth: "100%" }} />
		</>
	);
};


